/* --- MOBILE STYLES --- */

.landing .name {
  font-size: 48px;
}
.landing .description {
  font-size: 22px;
  font-family: var(--monospace, monospace);
}

.image-container {
  position: absolute;
  inset: 0;
}

.landingImage {
  object-fit: cover;
  opacity: 0.4;
  mix-blend-mode: lighten;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-position: 50% 35%;
}

.landing .socialIcons {
  align-self: flex-end;
  position: absolute;
  bottom: 20px;
}

/* --- MEDIA QUERIES --- */

@media (min-width: 750px) {
  .landing .name {
    font-size: 70px;
  }

  .landing .description {
    font-size: 32px;
  }

  .landing .socialIcons {
    flex-direction: column;
    left: 50px;
  }
}
