/* --- MOBILE STYLES --- */

.about {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing);
}

.aboutContainer {
  border-bottom: 1px solid var(--grey);
}

.contentContainer {
  padding: 10px;
}

.contentContainer h1,
.contentContainer h2,
.contentContainer h3,
.contentContainer h4,
.contentContainer p,
.contentContainer ul {
  color: var(--text-color);
  font-weight: lighter;
}

.contentContainer h1,
.contentContainer h2,
.contentContainer h3,
.contentContainer h4,
.contentContainer h5 {
  margin-bottom: 2px;
  margin-top: 25px;
  color: var(--hl-color);
  font-weight: bold;
}
