.tag-name-buttons-container {
  display: flex;
  justify-content: center;
  color: var(--text-color);
  font-size: 14px;
}

.tag-name {
  padding: 14px 0;
  transition: text-shadow 220ms ease-in;
  cursor: pointer;
}

.tag-name:hover {
  text-shadow: 0 0 10px #fff, 0 0 25px #fff;
}

.tag-name:has(.tag-name-checkbox:checked) {
  text-shadow: 0 0 10px #fff, 0 0 25px #fff, 0 0 30px #74b3ce, 0 0 40px #74b3ce,
  0 0 50px #74b3ce, 0 0 60px #74b3ce;
}

.tag-name-checkbox {
  display: none;
  visibility: hidden;
}

.tag-name-label {
  padding: 15px 20px;
  cursor: pointer;
  user-select: none;
}

@media screen and (max-width: 640px) {
  .tag-name-label {
    padding: 12px;
  }
}
