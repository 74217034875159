.gallery-image {
  position: relative;
}

.gallery-image[data-image-title]:before {
  position: absolute;
  content: attr(data-image-title);
  overflow: hidden;
  opacity: 0;
  inset: auto 0 0 0;
  padding: 8;
  color: var(--text-color);
  text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.08),
  1px 1px 1px rgba(0, 0, 0, 0.5);
  transition: opacity 220ms ease-in;
}

.gallery-image[data-image-title]:hover:before {
  opacity: 1;
}
