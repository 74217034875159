/* --- MOBILE STYLES --- */

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing);
}

.aboutContainer {
  border-bottom: 1px solid var(--grey);
}

.personalImage img {
  height: 330px;
  z-index: 50;
  border-radius: 0;
  padding: 6px;
  border: 1px solid var(--grey);
}

.personalImage {
  height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.personalImage .snake {
  position: relative;
  height: 100%;
}

.personalImage .snake::before, .personalImage .snake::after {
  content: '';
  display: block;
  position: absolute;
  outline: 1px solid var(--hl-color);
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.personalImage .snake::after, .personalImage .snake::before { animation-name: snake-border;  }
.personalImage .snake::after { animation-delay: -3s }

.contentContainer {
  padding: 10px;
}

.contentContainer h4 {
  color: var(--hl-color);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

.contentContainer h5 {
  color: var(--text-color);
  font-size: 20px;
  margin-bottom: 15px;
}

.contentDescription {
  color: var(--secondary-text-color);
}

.infoContainer {
  border-top: 1px solid var(--grey);
  margin-top: var(--sm-spacing);
  padding-top: var(--sm-spacing);
}

.info {
  padding-left: 0;
}

.infoContainer span {
  color: var(--text-color);
}

.infoContainer p {
  color: var(--secondary-text-color);
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 10px;
}

.infoContainer a {
  color: var(--hl-color);
}

.infoContainer a:hover {
  color: #03b979;
}

.about .socialIcons .icon {
  display: none;
}

@media (min-width: 750px) {
  .about .socialIcons .icon {
    display: inline;
  }

  .about .socialIcons::before {
    background-color: var(--grey);
    content: "";
    height: 2px;
    width: var(--sm-spacing);
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 10px;
  }
}



/* Keyframes */

@keyframes snake-border {

  90% { top: 0; height: 40%; }
  100% { left: 0; top: 0; width: 0; height: 0; } 0% { left: 0; top: 0; width: 0; height: 0; }

  15% { width: 40%; }
  25% { left: 100%; top: 0; width: 0; height: 0; }

  40% { height: 40%; }
  50% { left: 100%; top: 100%; width: 0; height: 0; }

  65% { left: 0; width: 40%; }
  75% { left: 0; top: 100%; width: 0; height: 0; }
}

